import * as React from 'react';

import { graphql } from 'gatsby';
import { Heading, Paragraph } from 'theme-ui';

import Layout from '../components/layout';

const IndexPage = ({ data }) => {
  return (
    <Layout slugs={data}>
      <title>Help | DFS Crunch</title>

      <Heading sx={{ mb: 2 }}>
        Introduction
      </Heading>

      <Paragraph
        sx={{
          lineHeight: '24px',
        }}
      >
        You can find FAQ answer here, if you can't find the answer, please contact us at our Discord community, or
        send email to <a href='mailto:support@dfscrunch.com?subject=I Have DFS Question'>support@dfscrunch.com</a>
      </Paragraph>

    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(sort: {fields: frontmatter___ordering, order: DESC}) {
      nodes {
        id
        slug
        frontmatter {
          ordering
          title
        }
      }
    }
  }
`

export default IndexPage;
